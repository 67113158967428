import { createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as Icons from '@element-plus/icons-vue'  //全局图标
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";
import '../src/assets/css/common.css'
import '../src/assets/css/main.css'
import '../src/assets/js/rem'


// 引入自适应
// import 'lib-flexible/flexible.js'

import print from 'vue3-print-nb'

const app = createApp(App);
import common from '../src/common/utils.js';//全局js文件

app.config.globalProperties.$common=common;
app.use(store)
app.use(router)
app.use(NutUI)
app.use(ElementPlus,{
    zIndex: 3000,
    size: 'large'
})
app.use(print)
// app.use(axiosApi)
app.mount('#app')
Object.keys(Icons).forEach((key) => {
    app.component(key, Icons[key])
  })
  

