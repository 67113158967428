<template>
    <div class="container">
        <div class="main_content" id="user_center_page" style="margin-top:0;">
            <div class="user_page_top">
                <img src="@/assets/img/user_bg.png" alt="">
                <div class="user_info">
                    <div class="img">
                        <img src="@/assets/img/people/01-男性.png" alt="">
                    </div>
                    <span>1588****9630</span>
                </div>
                <div class="user_menues">
                    <div @click="toPage('OrderList',0)">
                        <h4>我的订单</h4>
                        <a >查看全部<img src="@/assets/img/icon/right.png" alt=""></a>
                    </div>
                    <ul>
                        <li>
                            <a @click="toPage('OrderList',1)">
                                <i>1</i>
                                <img style="width: 0.57rem;" src="@/assets/img/icon/user_menu_1.png" alt="">
                                <p>待付款</p>
                            </a>
                        </li>
                        <li>
                            <a @click="toPage('OrderList',2)">
                                <img style="width: 0.49rem;" src="@/assets/img/icon/user_menu_2.png" alt="">
                                <p>待预约</p>
                            </a>
                        </li>
                        <li>
                            <a @click="toPage('OrderList',3)">
                                <img style="width: 0.42rem;" src="@/assets/img/icon/user_menu_3.png" alt="">
                                <p>待体检</p>

                            </a>
                        </li>
                        <li>

                            <a @click="toPage('OrderList',4)">
                                <img style="width: 0.63rem;" src="@/assets/img/icon/user_menu_4.png" alt="">
                                <p>退款</p>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <ul class="user_list_nav">
                <!--优惠券-->
                <!-- <li class="Yhq">
            <a href="javascript:;">
                <div style="display:flex;align-items:center;">
                    <img src="@/assets/img/img/yhq.png" alt="">优惠券
                </div>
                <img src="@/assets/img/icon/right.png" alt="">
            </a>
            <div class="baoqian"><img src="@/assets/img/baoqian.png" alt=""></div>
        </li> -->
                <!--体检人信息-->
                <li class="Tjrxx">
                    <a @click="toPage('Examinator')">
                        <div>
                            <img src="@/assets/img/icon/message.png" alt="">体检人信息
                        </div>

                        <img src="@/assets/img/icon/right.png" alt="">
                    </a>
                    <div class="baoqian"><img src="@/assets/img/baoqian.png" alt=""></div>
                </li>
                <li onclick="$(this).find('.baoqian').show()">
                    <a href="">
                        <div>
                            <img src="@/assets/img/icon/1.png" alt="">查询报告
                        </div>
                        <img src="@/assets/img/icon/right.png" alt="">
                    </a>
                </li>

                <li>
                    <a @click="toPage('Goods',1)">
                        <div>
                            <img src="@/assets/img/icon/3.png" alt="">我的收藏
                        </div>
                        <img src="@/assets/img/icon/right.png" alt="">
                    </a>
                </li>
                <li style="border-bottom:1px solid #f5f5f5;">
                    <a @click="toPage('Goods',2)">
                        <div>
                            <img src="@/assets/img/icon/4.png" alt="">浏览记录
                        </div>
                        <img src="@/assets/img/icon/right.png" alt="">
                    </a>
                </li>
                <!-- <li style="margin-top:-0.3rem;" id="oLi">
            <a>
                <div>
                    <img src="@/assets/img/icon/kkzx.png" alt="">APP下载
                </div>
                <img src="@/assets/img/icon/right.png" alt="">
            </a>
        </li> -->
                <!-- <li>
                    <a href="/yygzh/Dttj?yybh=YY201509141935330001">
                        <div>
                            <img src="@/assets/img/icon/4.png" alt="套餐推荐记录">套餐推荐记录
                        </div>
                        <img src="@/assets/img/icon/right.png" alt="套餐推荐记录">
                    </a>
                </li> -->

            </ul>

            <div class="user_page_footer">
                <a href="" class="user_out">退出登录</a>
            </div>
        </div>

    </div>
    <van-tabbar v-model="active">
        <van-tabbar-item icon="home-o" to="/">主页</van-tabbar-item>
        <van-tabbar-item icon="friends-o" to="/User">我的</van-tabbar-item>
    </van-tabbar>
</template>

<script>
import "vant/lib/index.css";
import { ref } from "vue";
import { Tabbar, TabbarItem, Popup } from "vant";

export default {
    components: {
        vanPopup: Popup,
        vanTabbar: Tabbar,
        vanTabbarItem: TabbarItem,
    },
    setup() {
        const active = ref(1);
        return {
            active,
        };
    },
    created() {},
    methods: {
        toPage(page,type) {
            this.$router.push({
                name: page,
                query: { type: type },
            });
        },
    },
    data() {
        return {
            
        };
    },
};
</script>

<style scoped>
.container {
    padding-bottom: 50px;
}
</style>