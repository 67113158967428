<template>
    <van-nav-bar :title="packageList.name" left-text="返回" left-arrow @click-left="onClickLeft">
        <template #right>
            <router-link to="/User">
            <i  class="van-badge__wrapper van-icon van-icon-friends-o" style="font-size:25px"></i>
            </router-link>
        </template>
    </van-nav-bar>
    <div class="container">
        <div class="main_img" style="height: auto;">
            <div>
                <img src="https://img.kktijian.com:8443/gr/img/OldImages/PC网站图/入职体检/01-7-入职-男女.png" alt="">
            </div>
        </div>
        <div class="tc_info" style="border-radius: 0 0 0.16rem 0.16rem;">
            <div class="09info" style="margin-top:30px;">
                <div class="info09_hd">
                    <div class="t1">
                        <div class="price1">￥345.00</div>
                    </div>
                    <div class="t2">
                        <div class="t2_1">
                            <span>2302</span>
                            <p>已约</p>
                        </div>
                        <div class="t2_3"><em></em></div>
                        <div class="collect_box active">
                            <i></i>
                            <p>收藏</p>
                        </div>
                    </div>
                </div>
                <div class="name09">入职体检套餐3</div>
                <div class="info09_bd">适用于求职人员</div>
            </div>
        </div>
        <div class="bdLine"></div>
        <div style="border-radius: 0.16rem;background: #fff;overflow: hidden;">
            <!-- 新增机构信息 -->
            <div class="jginfo08">
                <div class="img">
                    <img src="https://img.kktijian.com:8443/gr/img/OldImages/JiGou/20210301/YY201509141935330001/bab7b698553f49fc9baf4e8cf2b8982a.jpg" alt="衡阳市中心医院健康管理（体检）中心">
                </div>
                <p>衡阳市中心医院健康管理（体检）中心</p>
                <span></span>
            </div>
            <div id="bz05" @click="show=true"><img src="@/assets/img/icon/bz09.png"></div>
            <van-popup v-model:show="show" position="bottom" round :style="{ height: '5.55rem' }">
                <div class="common_model_fwbz">
                    <div class="hd">服务保障<span class="close" @click="show=false;"><img src="@/assets/img/icon/clear.png" alt="" data-isloaded="1"></span></div>
                    <div class="con">
                        <ul>
                            <li>
                                <div class="t1"><img src="@/assets/img/icon/bz_4.png" alt="" data-isloaded="1"></div>
                                <div class="t2">
                                    <div class="t2_1">官方渠道</div>
                                    <div class="t2_2">医院自有平台，官方线上渠道，可享受极速预约通道</div>
                                </div>
                            </li>
                            <li>
                                <div class="t1"><img src="@/assets/img/icon/bz_2.png" alt="" data-isloaded="1"></div>
                                <div class="t2">
                                    <div class="t2_1">免费改期</div>
                                    <div class="t2_2">
                                        每位客户拥有2次通过网站直接改期的机会。如因特殊情况需再次改期，只需联系康康体检网客服审核，确认原因后即可改期。改期不收任何服务费。
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="t1"><img src="@/assets/img/icon/bz_3.png" alt="" data-isloaded="1"></div>
                                <div class="t2">
                                    <div class="t2_1">未检可退</div>
                                    <div class="t2_2">
                                        用户支付成功但未预约体检时间，可以全额退款。
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </van-popup>
            <div class="zjky_box zjky_box02 clearfix" id="choose_date2" @click="calendarShow = true">
                <h3 class="fl" style="font-weight: bold;">最近可约</h3>
                <div class="center fl">
                    2月22日、2月23日、2月24日
                </div>
                <span class="fr"><img src="@/assets/img/icon/toggle2.png" alt=""></span>
            </div>
            <van-calendar readonly v-model:show="calendarShow" title="体检日期选择" :show-confirm="disable" :formatter="formatter" @confirm="onConfirm" />

        </div>
        <div class="bdLine"></div>
        <div class="main_tab_wrap">
            <van-tabs v-model:active="active">
                <van-tab title="项目详情">
                    <div class="jgjs_box">
                        <div class="item">
                            <div class="title"><img src="@/assets/img/icon/circle.png" alt="">套餐特点</div>
                            <div class="jgjs_content content"></div>
                        </div>
                        <div class="bdLine"></div>
                        <div class="item">
                            <div class="title"><img src="@/assets/img/icon/circle.png" alt="">适用人群</div>
                            <div class="content">
                                <ul class="imgList">

                                    <li>
                                        <img src="@/assets/img/people/08-男女不限.png" alt="">
                                        <p>男女不限</p>
                                    </li>
                                    <li>
                                        <img src="@/assets/img/people/07-已婚.png" alt="">
                                        <p>已婚</p>

                                    </li>
                                    <li>
                                        <img src="@/assets/img/people/06-未婚.png" alt="">
                                        <p>未婚</p>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="bdLine"></div>
                        <!-- 菏泽市立医院 -->
                        <div class="item">
                            <div class="title"><img src="@/assets/img/icon/circle.png" alt="">疾病筛查</div>
                            <div class="content">
                                <ul class="imgList imgList2">
                                    <li><img src="@/assets/img/ache/胸肺部检查.png" alt="">
                                        <p>胸肺部检查</p>
                                    </li>
                                    <li><img src="@/assets/img/ache/肝胆检查.png" alt="">
                                        <p>肝胆检查</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="bdLine"></div>
                        <div class="detail_tc_wrap">
                            <div class="detail_tc_box2">
                                <div class="hd"><img src="@/assets/img/icon/circle.png" alt="">套餐项目(8)</div>
                                <div class="title">
                                    <div class="t1">检查项目</div>
                                    <div class="t2">检查指标意义</div>
                                </div>
                                <div class="item">
                                    <div class="t1">医技检查(3项)</div>
                                    <dl>
                                        <dt>胸部照片</dt>
                                        <dd>通过X线拍片检查两肺、心脏、纵隔、膈、胸膜，判断有无炎症、肿瘤等。</dd>
                                    </dl>
                                    <dl>
                                        <dt>腹部彩超</dt>
                                        <dd>对人体腹部内脏器官的状况和各种病变（如肿瘤、结石、积水、脂肪肝等）提供高清晰度的彩色动态超声断层图像判断，依病灶周围血管情况、病灶内血流血供情况鉴别良恶性病变</dd>
                                    </dl>
                                    <dl>
                                        <dt>心电图</dt>
                                        <dd>利用图形描记与心脏跳动有关的电位变化，有助于判断是否有心律失常、各种心脏病引起的心房或心室肥大、心肌炎、心肌缺血、心肌梗塞及全身性疾病引起心脏病变。</dd>
                                    </dl>
                                </div>
                                <div class="item">
                                    <div class="t1">实验室检查(3项)</div>
                                    <dl>
                                        <dt>小便常规</dt>
                                        <dd>可提示有无泌尿系统疾患：如急、慢性肾炎，肾盂肾炎，膀胱炎，尿道炎，肾病综合征，狼疮性肾炎，血红蛋白尿，肾梗塞、肾小管重金属盐及药物导致急性肾小管坏死，肾或膀胱肿瘤以及有无尿糖等。</dd>
                                    </dl>
                                    <dl>
                                        <dt>血常规</dt>
                                        <dd>通过检测血液细胞的计数及不同种类细胞、成分的分类来反映身体状况，如：贫血、感染等。</dd>
                                    </dl>
                                    <dl>
                                        <dt>肝功能</dt>
                                        <dd>肝功能检查主要是指对能直接或间接反映肝脏代谢功能的生理指标的检测。</dd>
                                    </dl>
                                </div>
                                <div class="item">
                                    <div class="t1">其它项目(1项)</div>
                                    <dl>
                                        <dt>材料费、采血费、总结报告费、结果归档等。</dt>
                                        <dd>抽血费及材料费</dd>
                                    </dl>
                                </div>
                                <div class="item">
                                    <div class="t1">科室检查(1项)</div>
                                    <dl>
                                        <dt>一般检查4</dt>
                                        <dd>通过测量人体身高、体重等，科学判断体重是否标准、体脂肪是否超标等。</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>

                    </div>
                </van-tab>
                <van-tab title="预约流程">
                    <!-- 体检流程 -->
                    <div class="jgjs_box">
                        <div class="item">
                            <div class="title"><img src="@/assets/img/icon/circle.png" alt="">预约流程</div>
                            <div class="content">
                                <img src="@/assets/img/lct.png" alt="" style="margin-bottom: 0.4rem;">
                                <ol class="num_ol">
                                    <li>选择需要的体检套餐，完成下单</li>
                                    <li>可在付款完成后，“立即预约”或到“我的订单”中预约体检。</li>
                                    <li>
                                        体检当日现场出示身份证即可体检， 注 : 到医院无需另行支付其他费用
                                        <!-- 大连大学新华医院 -->
                                        <span>(现场加项、申请快递报告除外)</span>
                                        。
                                    </li>
                                    <li>
                                        体检报告一般在3-15个工作日出结果，
                                        <span>部分医院支持在线查询报告、报告邮寄，各医院领取时间、方式不同，</span>
                                        具体的报告领取方式和时间可现场咨询医院工作人员。
                                    </li>

                                </ol>
                            </div>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="体检须知">
                    <div class="jgjs_box">
                        <div class="item">
                            <div class="title"><img src="@/assets/img/icon/circle.png" alt="">体检须知</div>
                            <div class="content">
                                <ul class="tijian_step">
                                    <li>
                                        <div class="step_title"><span>01</span>体检前</div>
                                        <div class="hs_left_border">
                                            <ol class="num_ol">
                                                <li>体检前一天请您清淡饮食,勿饮酒、勿劳累。体检当天请空腹禁食。</li>
                                                <li>体检前一天要注意休息，晚上8点后不再进食。避免剧烈运动和情绪激动，保证充足睡眠，以免影响体检结果。</li>
                                                <li>例假期间不宜做妇科、尿液检查。</li>
                                            </ol>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="step_title"><span>02</span>体检中</div>
                                        <div class="hs_left_border">
                                            <ol class="num_ol">
                                                <li>需空腹检查的项目为抽血、腹部B超、数字胃肠，胃镜及其它标注的体检项目。</li>
                                                <li>做膀胱、子宫、附件B超时请勿排尿，如无尿需饮水至膀胱充盈。做妇科检查前应排空尿。</li>
                                                <li>未婚女性不做妇科检查；怀孕的女性请预先告知医护人员,不安排做放射及其他有影响的检查。</li>
                                                <li>做放射线检查前,请您除去身上佩戴的金银、玉器等饰物。</li>
                                                <li>核磁共振检查，应禁止佩带首饰、手表、传呼、手机等金属物品，磁卡也不应带入检查室，以防消磁。</li>
                                            </ol>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="step_title"><span>03</span>体检后</div>
                                        <div class="hs_left_border">
                                            <ol class="num_ol">
                                                <li>全部项目完毕后请您务必将体检单交到前台。</li>
                                                <li>请您认真听取医生的建议,及时复查、随诊或进一步检查治疗。</li>
                                                <li>请您保存好体检结果，以便和下次体检结果作对照也可作为您就医时的资料。</li>
                                            </ol>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <div class="bdLine"></div>
    </div>
    <van-action-bar>
        <van-action-bar-icon icon="chat-o" text="客服" @click="service=true" />
        <div style="font-size: 0.4rem;color: #fe1605;line-height: 0.4rem;width:4rem">￥{{packageList.price}}</div>
        <van-action-bar-button size="small" type="danger" text="立即预约" @click="toOrder()" />
    </van-action-bar>
    <van-popup v-model:show="service" position="bottom" round :style="{ height: '1.8rem' }"  class="service">
        <p onclick="NTKF.im_openInPageChat();">在线客服（8:00-22:00）</p>
        <p><a href="tel:400-1000-920" class="a_phone" rel="nofollow">400-1000-920（8:00-22:00）</a></p>
    </van-popup>
</template>

<script>
import "vant/lib/index.css";
import { ref } from "vue";
import {
    Tab,
    Tabs,
    NavBar,
    ActionBar,
    ActionBarIcon,
    ActionBarButton,
    Popup,
    Calendar,
    Tabbar,
    TabbarItem,
} from "vant";

export default {
    components: {
        vanTab: Tab,
        vanTabs: Tabs,
        vanNavBar: NavBar,
        vanActionBar: ActionBar,
        vanPopup: Popup,
        vanActionBarIcon: ActionBarIcon,
        vanActionBarButton: ActionBarButton,
        vanCalendar: Calendar,
        vanTabbar: Tabbar,
        vanTabbarItem: TabbarItem,
    },
    setup() {
        const onClickLeft = () => history.back();
        const active = ref(0);
        const date = ref("");
        const calendarShow = ref(false);
        const formatter = (day) => {
            const month = day.date.getMonth() + 1;
            const date = day.date.getDate();
            const week = day.date.getDay();

            if (week != 0 && week != 6) {
                if (month > 2) day.bottomInfo = "可约";
                if (month === 2) {
                    if (date === 21) {
                        day.type = "disabled";
                        day.bottomInfo = "不可约";
                    } else {
                        day.bottomInfo = "可约";
                    }
                }
            } else {
                day.type = "disabled";
                day.bottomInfo = "不可约";
            }

            return day;
        };
        const onConfirm = (value) => {
            calendarShow.value = false;
            date.value = formatDate(value);
        };
        return {
            onClickLeft,
            active,
            date,
            calendarShow,
            formatter,
            onConfirm,
        };
    },
    created() {},
    methods: {
        toOrder() {
            this.$router.push({
                name: "Order",
                query: { id: this.$route.query.id },
            });
        },
    },
    data() {
        return {
            disable: false,
            show: false,
            calendarShow: false,
            service: false,
            packageList: {
                name: "入职体检",
                id: 1,
                price: 998,
                projectList: [
                    {
                        title: "血常规",
                        describe:
                            "可以检查贫血，炎症.可以检查贫血，炎症.可以检查贫血，炎症.可以检查贫血",
                    },
                    {
                        title: "肝功15项",
                        describe: "可了解肝功能是否有受损",
                    },
                    {
                        title: "肾功能5项",
                        describe:
                            "可以检查贫血，炎症.可以检查贫血，炎症.可以检查贫血，炎症.可以检查贫血",
                    },
                    {
                        title: "血脂六项",
                        describe:
                            "可以检查贫血，炎症.可以检查贫血，炎症.可以检查贫血，",
                    },
                    {
                        title: "血常规",
                        describe:
                            "可以检查贫血，炎症.可以检查贫血，炎症.可以检查贫血，",
                    },
                ],
            },
        };
    },
};
</script>

<style scoped>
.container {
    background: rgb(236, 236, 236);
    min-height: 80vh;
    padding-bottom: 50px;
}
</style>