<template>
    <van-nav-bar title="订单详情" left-text="返回" left-arrow @click-left="onClickLeft">
        <template #right>
            <router-link to="/User">
                <i class="van-badge__wrapper van-icon van-icon-friends-o" style="font-size:25px"></i>
            </router-link>
        </template>
    </van-nav-bar>
    <div class="container">
        <div class="order_detail_top">
            <img v-if="orderDetail.status!=0" src="@/assets/img/img/bj.png" alt="">
            <img v-if="orderDetail.status==0" src="@/assets/img/bg5.png" alt="">
            <div class="status" style="display:flex;justify-content:space-between;align-items:center;font-size:0.35rem;">
                <div>
                    <div style="display: flex;justify-content: flex-start;align-items: center;">
                        <img v-if="orderDetail.status==1" style="margin-right:0.1rem;width:0.4rem;" src="@/assets/img/img/clock.png" alt="">
                        <h2>{{orderDetail.statusText}}</h2>

                    </div>
                    <div v-if="orderDetail.status==1" style="font-size:0.25rem;margin-top:0.2rem;">剩余时间：<span class="djs">{{orderDetail.remainTime}}</span></div>
                    <div v-if="orderDetail.status==0" style="font-size:0.25rem;margin-top:0.4rem;">欢迎再次选择 衡阳市中心医院健康管理（体检）中心</div>
                </div>
                <div style="margin-right:0.9rem;font-size:0.35rem;" v-if="orderDetail.status==1">
                    <div>需支付金额</div>
                    <div>￥<span style="font-size: 0.4rem;">{{orderDetail.price}}</span></div>
                </div>
            </div>
        </div>
        <div style="border-radius: 0.16rem;overflow:hidden;background:white">
            <div class="order_info clearfix">
                <div class="fl"><img src="https://img.kktijian.com:8443/gr/img/OldImages/PC网站图/入职体检/01-7-入职-男女.png"></div>
                <div class="oh">
                    <h4>{{orderDetail.name}}</h4>
                    <p>衡阳市中心医院健康管理（体检）中心</p>
                    <dl>
                        <dt>￥{{orderDetail.price}}</dt>
                        <dd style="display:none">
                            <input type="text" value="1" id="TCSL" name="TCSL" readonly="readonly">
                        </dd>
                        <dd>
                            <input id="button" @click="detailShow=true" type="button" name="name" value="项目详情" style="margin-top: 0.2rem; border: none; width: 1.4rem; height: 0.4rem; border-radius: 0.2rem; font-size: 0.2rem; border: 1px solid #ccc; background: #fff; line-height: 0.2rem;">
                        </dd>
                    </dl>
                </div>
                <van-popup v-model:show="detailShow" position="bottom" :style="{ height: '10rem' }">
                    <div class="box1" style="display: block;">
                        <div class="box1-1" style="height: 10rem;">
                            <div class="box-position" style="position:relative;">
                                <div class="box2-1">
                                    <span>入职体检套餐3</span>
                                </div>
                                <div class="box2" style="justify-content:center;padding-top: 0.2rem;background-color:#fff;">
                                    <div class="box2-2" style="font-weight: bold; color: #333; background-color: #f0f0f0; text-align: center; height: 0.5rem; width: 2rem; line-height: 0.5rem; border-radius: 0.5rem; font-size: 0.2rem; margin: 0 auto;">医技检查(3项)</div>
                                    <dl style="padding:0 0.2rem;">
                                        <dt style="margin-left:0.2rem;margin-right:0.3rem;padding-bottom:0.1rem;font-weight:bold;color:#333;font-size:0.25rem;">胸部照片</dt>
                                        <dd style="margin-left:0.4rem;margin-right:0.4rem;text-align:justify;font-size:0.2rem;">通过X线拍片检查两肺、心脏、纵隔、膈、胸膜，判断有无炎症、肿瘤等。</dd>
                                    </dl>
                                    <dl style="padding:0 0.2rem;">
                                        <dt style="margin-left:0.2rem;margin-right:0.3rem;padding-bottom:0.1rem;font-weight:bold;color:#333;font-size:0.25rem;">腹部彩超</dt>
                                        <dd style="margin-left:0.4rem;margin-right:0.4rem;text-align:justify;font-size:0.2rem;">对人体腹部内脏器官的状况和各种病变（如肿瘤、结石、积水、脂肪肝等）提供高清晰度的彩色动态超声断层图像判断，依病灶周围血管情况、病灶内血流血供情况鉴别良恶性病变</dd>
                                    </dl>
                                    <dl style="padding:0 0.2rem;">
                                        <dt style="margin-left:0.2rem;margin-right:0.3rem;padding-bottom:0.1rem;font-weight:bold;color:#333;font-size:0.25rem;">心电图</dt>
                                        <dd style="margin-left:0.4rem;margin-right:0.4rem;text-align:justify;font-size:0.2rem;">利用图形描记与心脏跳动有关的电位变化，有助于判断是否有心律失常、各种心脏病引起的心房或心室肥大、心肌炎、心肌缺血、心肌梗塞及全身性疾病引起心脏病变。</dd>
                                    </dl>
                                </div>
                                <div class="box2" style="justify-content:center;padding-top: 0.2rem;background-color:#fff;">
                                    <div class="box2-2" style="font-weight: bold; color: #333; background-color: #f0f0f0; text-align: center; height: 0.5rem; width: 2rem; line-height: 0.5rem; border-radius: 0.5rem; font-size: 0.2rem; margin: 0 auto;">实验室检查(3项)</div>
                                    <dl style="padding:0 0.2rem;">
                                        <dt style="margin-left:0.2rem;margin-right:0.3rem;padding-bottom:0.1rem;font-weight:bold;color:#333;font-size:0.25rem;">小便常规</dt>
                                        <dd style="margin-left:0.4rem;margin-right:0.4rem;text-align:justify;font-size:0.2rem;">可提示有无泌尿系统疾患：如急、慢性肾炎，肾盂肾炎，膀胱炎，尿道炎，肾病综合征，狼疮性肾炎，血红蛋白尿，肾梗塞、肾小管重金属盐及药物导致急性肾小管坏死，肾或膀胱肿瘤以及有无尿糖等。</dd>
                                    </dl>
                                    <dl style="padding:0 0.2rem;">
                                        <dt style="margin-left:0.2rem;margin-right:0.3rem;padding-bottom:0.1rem;font-weight:bold;color:#333;font-size:0.25rem;">血常规</dt>
                                        <dd style="margin-left:0.4rem;margin-right:0.4rem;text-align:justify;font-size:0.2rem;">通过检测血液细胞的计数及不同种类细胞、成分的分类来反映身体状况，如：贫血、感染等。</dd>
                                    </dl>
                                    <dl style="padding:0 0.2rem;">
                                        <dt style="margin-left:0.2rem;margin-right:0.3rem;padding-bottom:0.1rem;font-weight:bold;color:#333;font-size:0.25rem;">肝功能</dt>
                                        <dd style="margin-left:0.4rem;margin-right:0.4rem;text-align:justify;font-size:0.2rem;">肝功能检查主要是指对能直接或间接反映肝脏代谢功能的生理指标的检测。</dd>
                                    </dl>
                                </div>
                                <div class="box2" style="justify-content:center;padding-top: 0.2rem;background-color:#fff;">
                                    <div class="box2-2" style="font-weight: bold; color: #333; background-color: #f0f0f0; text-align: center; height: 0.5rem; width: 2rem; line-height: 0.5rem; border-radius: 0.5rem; font-size: 0.2rem; margin: 0 auto;">其它项目(1项)</div>
                                    <dl style="padding:0 0.2rem;">
                                        <dt style="margin-left:0.2rem;margin-right:0.3rem;padding-bottom:0.1rem;font-weight:bold;color:#333;font-size:0.25rem;">材料费、采血费、总结报告费、结果归档等。</dt>
                                        <dd style="margin-left:0.4rem;margin-right:0.4rem;text-align:justify;font-size:0.2rem;">抽血费及材料费</dd>
                                    </dl>
                                </div>
                                <div class="box2" style="justify-content:center;padding-top: 0.2rem;background-color:#fff;">
                                    <div class="box2-2" style="font-weight: bold; color: #333; background-color: #f0f0f0; text-align: center; height: 0.5rem; width: 2rem; line-height: 0.5rem; border-radius: 0.5rem; font-size: 0.2rem; margin: 0 auto;">科室检查(1项)</div>
                                    <dl style="padding:0 0.2rem;">
                                        <dt style="margin-left:0.2rem;margin-right:0.3rem;padding-bottom:0.1rem;font-weight:bold;color:#333;font-size:0.25rem;">一般检查4</dt>
                                        <dd style="margin-left:0.4rem;margin-right:0.4rem;text-align:justify;font-size:0.2rem;">通过测量人体身高、体重等，科学判断体重是否标准、体脂肪是否超标等。</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>

                </van-popup>
            </div>

            <!-- 已选加项内容显示 -->
            <div class="order_now_wrap" v-if="orderDetail.status!=0">
                <h3>现在预约</h3>
                <input type="checkbox" class="my_switch" id="SFYY" name="SFYY" value="on" @click="personInfo=!personInfo">
            </div>
            <div v-if="orderDetail.status!=0" class="noLoing_tips" style="color: rgb(203, 147, 73); display: block;" v-show="!personInfo">可向右滑动按钮，现在填写信息预约体检</div>

            <div class="no_login" :class="personInfo?'showInfo':''">
                <div class="yellowBg-1">可购买后再填写、在体检前须完成预约</div>

                <div class="order_form" style="padding-bottom: 0;">
                    <!-- 中国医学科学院阜外医院深圳医院 -->
                    <div class="order_item">
                        <label>体检医院：</label>
                        <input type="text" value="衡阳市中心医院健康管理（体检）中心" readonly="readonly" unselectable="on" onfocus="this.blur();" style="font-size:0.28rem">
                    </div>
                    <div class="order_item" @click="calendarShow = true">
                        <label>体检日期：</label>
                        <input type="text" placeholder="请选择体检日期" id="choose_date" class="" name="YYSJ" readonly="readonly" unselectable="on" :value="submitInfo.date" onfocus="this.blur();">
                        <img src="@/assets/img/icon/date.png" alt="" class="dateImg">
                    </div>
                    <van-calendar v-model:show="calendarShow" title="体检日期选择" :formatter="formatter" @confirm="onDateConfirm" />
                    <div class="order_item">
                        <label>体检人：&nbsp;&nbsp;&nbsp;</label>
                        <input type="text" id="XM" name="XM" placeholder="请输入体检人姓名" style="font-size:0.28rem">
                        <div class="xztjr" @click="choose()">
                            <div style="display:flex;justify-content:center;align-items:center;">
                                <div class="icon"></div>
                            </div>
                            <div style="font-size:0.23rem;color:#ccc;">选择体检人</div>
                        </div>
                    </div>
                    <van-popup v-model:show="personShow" position="top" :style="{ height: '3rem' }" style="background:#f0f0f0">
                        <div class="person_item">
                            <div class="content">
                                <img src="@/assets/img/img/man1.png" alt="">
                                <div class="top-one" style="display: flex;justify-content: space-between;">
                                    <div style="display: flex;justify-content: flex-start;">
                                        <span style="height:0.36rem;display:inline-block;">罗威</span>&nbsp;&nbsp;
                                        <span class="spa1 c1">本人</span>
                                    </div>
                                </div>
                            </div>
                            <div class="body-content" style="margin-top:0.2rem;">
                                <label>电话号码：15889659630</label>
                            </div>
                            <div class="body-content" style="margin-top:0.2rem;padding-bottom:0.2rem;">
                                <label>证件号码：440982199304021211</label>
                            </div>
                        </div>
                    </van-popup>
                    <div class="order_item">
                        <label>婚姻状态：</label>
                        <div style="flex: 1;">
                            <span style="margin-right: 0.6rem;">
                                <input type="radio" name="HYZT" id="no" value="1" class="my_radio" v-model="submitInfo.marriage">
                                <label for="no">未婚</label>
                            </span>
                            <span>
                                <input type="radio" name="HYZT" id="yes" value="2" class="my_radio" v-model="submitInfo.marriage">
                                <label for="yes">已婚</label>
                            </span>
                        </div>
                    </div>
                    <div class="order_item" style="display: " @click="showPicker = true">
                        <label>证件类型：</label>
                        <a style="flex: 1 1 0%; cursor: pointer;" href="javascript:;" id="choose_id">{{submitInfo.certificates}}</a>
                    </div>
                    <van-popup v-model:show="showPicker" position="bottom">
                        <van-picker :columns="certificatesList" @confirm="onConfirm" @cancel="showPicker = false" />
                    </van-popup>
                    <div class="order_item">
                        <label>证件号码：</label>
                        <input type="text" id="SFZHM" name="SFZHM" v-model="submitInfo.certificatesNum" placeholder="请输入您的证件号码">
                    </div>
                    <div class="order_item box-sex" style="display: none;">
                        <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;性别：</label>
                        <div style="flex: 1;">
                            <span style="margin-right: 0.6rem;">
                                <input type="radio" name="XB" id="man" value="1" class="my_radio">
                                <label for="man">男</label>
                            </span>
                            <span>
                                <input type="radio" name="XB" id="woman" value="2" class="my_radio">
                                <label for="woman">女</label>
                            </span>
                        </div>
                    </div>
                    <div class="order_item box-birth" style="display: none;">
                        <label>出生日期：</label>
                        <input type="text" value="1980-01-01" readonly="readonly" name="CSRQ" id="choose_birdth">
                    </div>
                    <div class="order_item" id="div_dh">
                        <label>手机号码：</label>
                        <input type="text" placeholder="请输入手机号码" id="DH" name="DH" value="15889659630" class="phone_input">
                        <span class="num_clear"><img src="@/assets/img/icon/clear.png" alt=""></span>
                    </div>
                </div>
            </div>
            <div class="order_detail_info" style="margin:0.2rem 0;">
                <h3 style="color: #181f2f;font-size: 0.3rem;margin-bottom: 0.3rem;font-weight: normal;">订单信息</h3>
                <p>创建时间：{{orderDetail.date}}</p>
                <p>订单编号：D202202211440420001</p>
            </div>
            <div class="order_form" style="padding-bottom: 0;border-radius: 0.16rem;" v-if="orderDetail.status!=0">
                <h3>联系人信息</h3>
                <div class="order_item">
                    <label>手机号：</label>
                    <input type="text" id="lXDH" name="LXDH" value="15889659630" readonly="readonly" unselectable="on" onfocus="this.blur();" placeholder="请输入手机号">
                </div>
            </div>

            <div class="bdLine"></div>
            <div class="pay_wrap" v-if="orderDetail.status!=0">
                <div class="top">
                    <h3>支付方式</h3>
                    <ul>
                        <li><img src="@/assets/img/icon/gai.png" alt="">免费改期</li>
                        <li><img src="@/assets/img/icon/tui.png" alt="">未检可退</li>
                    </ul>
                </div>
                <div class="bottom">
                    <div>
                        <img src="@/assets/img/icon/wx.png" alt="">
                        <div>
                            <h4>微信支付</h4>
                            <p>微信红包能直接支付</p>
                        </div>
                    </div>
                    <input type="checkbox" class="my_radio" checked="checked" disabled="">
                </div>
            </div>
            <div class="bdLine"></div>
            <div class="pay_info" style="border-radius: 0.16rem;" v-if="orderDetail.status!=0">
                <p>
                    <span>订单总额</span>
                    <span class="DDZE">￥{{packageList.price}}</span>
                </p>
                <p>
                    <span>在线支付</span>
                    <span style="color:#ff4341;" class="zxzf">￥{{packageList.price}}</span>
                </p>
            </div>
        </div>
    </div>
    <van-action-bar>
        <van-action-bar-icon icon="chat-o" text="客服" @click="service=true" />
        <div style="font-size: 0.4rem;color: #fe1605;line-height: 0.4rem;width:4rem"><span v-if="orderDetail.status!=0">￥{{packageList.price}}</span></div>
        <van-action-bar-button v-if="orderDetail.status!=0" size="small" type="danger" text="去支付" @click="submit()" />
        <van-action-bar-button v-if="orderDetail.status==0" size="small" type="danger" text="再次购买" @click="submit()" />
    </van-action-bar>
    <van-popup v-model:show="service" position="bottom" round :style="{ height: '1.8rem' }" class="service">
        <p onclick="NTKF.im_openInPageChat();">在线客服（8:00-22:00）</p>
        <p><a href="tel:400-1000-920" class="a_phone" rel="nofollow">400-1000-920（8:00-22:00）</a></p>
    </van-popup>
</template>

<script>
import { ref ,getCurrentInstance} from "vue";
import "vant/lib/index.css";
import {
    CellGroup,
    Picker,
    Field,
    Popup,
    Checkbox,
    Tab,
    Tabs,
    Toast,
    NavBar,
    DatetimePicker,
    ActionBar,
    ActionBarIcon,
    ActionBarButton,
    Calendar,
    Dialog,
} from "vant";

export default {
    components: {
        vanPicker: Picker,
        vanField: Field,
        vanPopup: Popup,
        vanCellGroup: CellGroup,
        vanCheckbox: Checkbox,
        vanTab: Tab,
        vanTabs: Tabs,
        vanNavBar: NavBar,
        vanDatetimePicker: DatetimePicker,
        vanActionBar: ActionBar,
        vanActionBarIcon: ActionBarIcon,
        vanActionBarButton: ActionBarButton,
        vanCalendar: Calendar,
        vanDialog: Dialog,
    },
    setup() {
        const onClickLeft = () => history.back();
        const date = ref("");
        const calendarShow = ref(false);
        const formatter = (day) => {
            const month = day.date.getMonth() + 1;
            const date = day.date.getDate();
            const week = day.date.getDay();

            if (week != 0 && week != 6) {
                if (month > 2) day.bottomInfo = "可约";
                if (month === 2) {
                    if (date === 21) {
                        day.type = "disabled";
                        day.bottomInfo = "不可约";
                    } else {
                        day.bottomInfo = "可约";
                    }
                }
            } else {
                day.type = "disabled";
                day.bottomInfo = "不可约";
            }

            return day;
        };
        return {
            date,
            calendarShow,
            formatter,
            onClickLeft,
        };
    },
    created() {
        const {proxy} = getCurrentInstance();
        this.orderDetail.time=new Date().getTime();
        var statusText = ["已取消", "待支付", "待预约", "待体检", "退款"];
        var orderDetail=this.orderDetail;
        orderDetail.statusText = statusText[this.orderDetail.status];
        if (orderDetail.status == 1) {
            orderDetail.interval = "";
            //设置订单失效时间
            var time = Number(orderDetail.time) + 1000 * 60 * 60 * 2;
            var currentTime = new Date().getTime();
            var cm = time - currentTime;
            if (cm <= 1000 * 60 * 60 * 2 && cm > 0)
                orderDetail.remainTime = proxy.$common.toHHmmss(cm);
                proxy.$common.setTimer(cm,this.orderDetail)
            //this.setTimer(cm);
            console.log(this.orderDetail)
        }
        orderDetail.date = proxy.$common.format(Number(orderDetail.time));
        //获取当天日期
        var today = new Date();
        var year = today.getFullYear();
        var month = today.getMonth() + 1;
        var day = today.getDate();
        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;
        this.submitInfo.date = year + "-" + month + "-" + day;
        //获取证件信息
        this.certificatesList.forEach((item, index) => {
            if (index == 0) {
                this.submitInfo.certificates = item.text;
                this.submitInfo.certificatesType = item.type;
            }
        });
        // Dialog.confirm({
        //     title: "您的订单还未支付",
        //     message:
        //         "本院当日预约号源有限，请尽快完成支付，以免影响您的体检日程安排。",
        //     confirmButtonText: "立即支付",
        //     cancelButtonText: "查看订单",
        // })
        //     .then(() => {
        //         // on confirm
        //     })
        //     .catch(() => {
        //         // on cancel
        //     });
    },
    methods: {
        toggle() {
            this.personInfo = !personInfo;
        },
        onDateConfirm(value) {
            var year = value.getFullYear();
            var month = value.getMonth() + 1;
            var day = value.getDate();
            if (month < 10) month = "0" + month;
            if (day < 10) day = "0" + day;
            this.submitInfo.date = year + "-" + month + "-" + day;
            this.calendarShow = false;
        },
        onConfirm(val) {
            this.submitInfo.certificates = val.text;
            this.submitInfo.certificatesType = val.type;
            this.showPicker = false;
        },
        choose() {
            // if(!this.personList){
            //     Toast("尚未添加体检人");
            // }
            this.personShow = true;
        },
        submit() {
            var submitData = this.submitInfo;
            // if (!this.submitData) {
            //     Toast("请输入真实姓名");
            //     return false;
            // }
            // if (!submitData.telephone) {
            //     Toast("请输入联系电话");
            //     return false;
            // } else if (!/^1[3456789]\d{9}$/.test(this.telephone)) {
            //     Toast("请输入正确的联系电话");
            //     return false;
            // }

            console.log(submitData);
        },
    },
    data() {
        return {
            detailShow: false,
            personInfo: false,
            showPicker: false,
            service: false,
            personShow: false,
            packageList: {
                name: "入职体检",
                id: 1,
                price: 998,
                projectList: [
                    {
                        title: "血常规",
                        describe:
                            "可以检查贫血，炎症.可以检查贫血，炎症.可以检查贫血，炎症.可以检查贫血",
                    },
                    {
                        title: "肝功15项",
                        describe: "可了解肝功能是否有受损",
                    },
                    {
                        title: "肾功能5项",
                        describe:
                            "可以检查贫血，炎症.可以检查贫血，炎症.可以检查贫血，炎症.可以检查贫血",
                    },
                    {
                        title: "血脂六项",
                        describe:
                            "可以检查贫血，炎症.可以检查贫血，炎症.可以检查贫血，",
                    },
                    {
                        title: "血常规",
                        describe:
                            "可以检查贫血，炎症.可以检查贫血，炎症.可以检查贫血，",
                    },
                ],
            },
            certificatesList: [
                {
                    text: "身份证",
                    type: 1,
                },
                {
                    text: "港澳通行证/护照",
                    type: 2,
                },
            ],
            submitInfo: {
                date: null,
                name: null,
                certificates: null,
                certificatesType: null,
                certificatesNum: null,
                marriage: 1,
            },
            orderDetail: {
                id: 1,
                status: 1,
                name: "入职体检套餐3",
                time: "1645514224393",
                price: "345.00",
                id: 1,
            },
        };
    },
};
</script>

<style scoped>
.container {
    padding-bottom: 3.5rem;
}
.buy_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.yuyue_btn {
    width: 3rem;
}

.order_kefu_wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    background: none;
}

.oh h4 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.oh p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.showMessage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9997;
}

.showMessage .black {
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: black;
    opacity: 0.5;
    z-index: 9998;
}

.content-info {
    background-color: white;
    border-radius: 0.2rem;
    box-shadow: 0 0 2rem black;
}

.content-info .content-title {
    text-align: center;
    font-weight: bold;
    font-size: 0.4rem;
    height: 1rem;
    line-height: 1rem;
}

.content-info p {
    line-height: 0.5rem;
    text-indent: 0.35rem;
    padding: 0 0.2rem;
}

.content-button {
    text-align: center;
    padding: 0.2rem 0;
}

.content-button input {
    background-color: #4681fc;
    border-radius: 0.2rem;
    border: 0;
    color: white;
    padding: 0.2rem 0.5rem;
}

input[type="button"],
input[type="submit"],
input[type="file"],
button {
    cursor: pointer;
    outline: 0px;
    -webkit-appearance: none;
}
#mask-kk {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
}

.box1 {
    position: fixed;
    bottom: 0;
    display: none;
    z-index: 1000;
    margin-bottom: 0;
    border-radius: 0.16rem 0.16rem 0 0;
    height: 10rem;
    overflow: scroll;
    width: 100%;
}

.box1-1 {
    position: absolute;
    display: none;
    bottom: 0;
    display: block;
    width: 100%;
    height: 0;
}

.box2-1 {
    background: #f7f7f7;
    height: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 0.16rem 0.16rem 0 0;
    z-index: 999;
    font-size: 0.3rem;
}
.ybktc {
    width: 6rem;
    height: 6rem;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -3rem;
    margin-top: -3rem;
    background: #fff;
    box-shadow: 2px 2px 3px #aaaaaa;
    border-radius: 0.5rem;
    display: none;
    z-index: 1001;
}

.ybkContent1 {
    text-align: center;
    height: 1rem;
    font-size: 0.4rem;
    line-height: 1rem;
    border-bottom: 1px solid #f0f0f0;
}

.ybkContent2 {
    height: 4rem;
    padding: 0.1rem 0.2rem;
}

.ybkContent2-1 {
    text-align: justify;
}

.ybkContent3 {
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #f0f0f0;
}

.anBtn {
    height: 0.6rem;
    width: 1.6rem;
    background: #1486d1;
    color: #fff;
    border: none;
    border-radius: 0.16rem;
}
.zftc {
    width: 6rem;
    height: 4rem;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -3rem;
    margin-top: -2rem;
    background: #fff;
    box-shadow: 2px 2px 3px #aaaaaa;
    border-radius: 0.5rem;
    display: none;
    z-index: 1001;
}

.zftcBox1 {
    text-align: center;
    height: 1rem;
    font-size: 0.4rem;
    line-height: 1rem;
    border-bottom: 1px solid #f0f0f0;
}

.zftcBox2 {
    height: 2rem;
    padding: 0.1rem 0.2rem;
}

.zftcBox2-1 {
    text-align: justify;
    font-size: 0.3rem;
}

.zftcBox3 {
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #f0f0f0;
}

.zfBtn {
    height: 0.6rem;
    width: 1.6rem;
    background: #1486d1;
    color: #fff;
    border: none;
    border-radius: 0.16rem;
}
#mask-kkkk {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 210%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
}
#mask-kkkkk {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 210%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
}
#mask1-kk {
    width: 100%;
    height: 200%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
}

.yhqtc {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    z-index: 1000;
    margin-bottom: 0;
    border-radius: 0.16rem 0.16rem 0 0;
    height: 4rem;
    overflow: scroll;
    width: 100%;
    background-color: #fff;
}

.yhqtcImg {
    width: 0.2rem;
    height: 0.2rem;
    position: absolute;
    top: 0.1rem;
    right: 0.2rem;
}

.yhqtc1 {
    height: 0.6rem;
    line-height: 0.6rem;
    text-align: center;
    position: relative;
    font-weight: bold;
}

.yhqContent1,
.yhqContent2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.2rem;
    height: 0.6rem;
}
#mask_k {
    width: 100%;
    height: 300%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
}

#watching {
    width: 5rem;
    height: 3rem;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -1.5rem;
    margin-left: -2.5rem;
    z-index: 1000;
    display: none;
    background: #fff;
    border-radius: 0.2rem;
}

#watching1 {
    width: 5rem;
    height: 3.5rem;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -1.5rem;
    margin-left: -2.5rem;
    z-index: 1000;
    display: none;
    background: #fff;
    border-radius: 0.2rem;
}

#watch_one {
    height: 2rem;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem 0.3rem 0 0.3rem;
    text-align: justify;
    box-sizing: border-box;
    font-size: 0.3rem;
}

#watch_one1 {
    height: 2.5rem;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem 0.3rem 0 0.3rem;
    text-align: justify;
    box-sizing: border-box;
    font-size: 0.3rem;
}

#watch_two {
    height: 1rem;
    display: flex;
}

#watch_two1 {
    height: 1rem;
    display: flex;
}

.two-1 {
    flex: 1;
    height: 1rem;
    text-align: center;
    line-height: 1rem;
    border-right: 1px solid #ccc;
    font-size: 0.25rem;
}

.two-11 {
    flex: 1;
    height: 1rem;
    text-align: center;
    line-height: 1rem;
    font-size: 0.25rem;
}

.two-2 {
    flex: 1;
    height: 1rem;
    text-align: center;
    line-height: 1rem;
    font-size: 0.25rem;
    color: #0094ff;
}
#mask1 {
    width: 100%;
    height: 200%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
}

#choose_tjr {
    height: 3rem;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: 1000;
    display: none;
}

.no_tjr {
    height: 1rem;
    line-height: 1rem;
    font-size: 0.2rem;
    color: #ccc;
    text-align: center;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
}

.tjr_cancel {
    height: 1rem;
    line-height: 1rem;
    font-size: 0.2rem;
    color: #ccc;
    text-align: center;
}
.all-content {
    margin: 1.1rem 0.2rem 0.2rem 0.2rem;
    background-color: #fff;
    margin-bottom: 1.4rem;
}

.content {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin: 0.2rem;
    padding-top: 0.2rem;
}

.content img {
    width: 0.8rem;
    height: 0.8rem;
    display: block;
}

.top-one {
    width: calc(100% - 0.6rem);
    font-size: 0.3rem;
    height: 0.8rem;
    line-height: 0.8rem;
    margin-left: 0.4rem;
}

.spa1 {
    height: 0.36rem;
    line-height: 0.36rem;
    border: 1px solid #1890ff;
    width: 0.7rem;
    display: block;
    margin-top: 0.24rem;
    text-align: center;
    color: #1890ff;
    font-size: 0.24rem;
}

.body-content {
    padding-left: 1.2rem;
    margin: 0 0.2rem;
}

.body-content label {
    font-size: 0.3rem;
    width: 0.4rem;
}

.body-content input {
    width: calc(100% - 1.8rem);
    height: 0.4rem;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.2rem;
    background: #fff;
    z-index: 4;
}

.foot {
    border-radius: 0.28rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    background-color: #1890ff;
    height: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    color: #fff;
    z-index: 4;
    margin-top: 0.2rem;
    font-size: 0.3rem;
}

.foot-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.2rem;
    padding-bottom: 0.2rem;
    height: 0.8rem;
    border-radius: 0.2rem;
}
.person_item {
    margin: 0.2rem;
    background: white;
    border-radius: 10px;
}
</style>