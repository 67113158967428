<template>
  <div>
    <!-- 头部 -->
    <van-nav-bar
      title="添加体检人"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    >
      <template #right>
        <i
          class="van-badge__wrapper van-icon van-icon-friends-o"
          style="font-size: 25px"
        ></i>
      </template>
    </van-nav-bar>
    <!-- 表单部分 -->
    <div class="form">
      <van-form ref="form">
        <!-- 输入名字 -->
        <van-cell-group>
          <van-field
            v-model="tjrList.name"
            name="姓名"
            label="姓名"
            placeholder="请输入您的姓名"
            :rules="[{ required: true, message: '请输入您的姓名' }]"
          />
        </van-cell-group>
        <!-- 选择证件类型 -->
        <van-cell-group>
          <van-field
            v-model="tjrList.idType"
            is-link
            readonly
            name="picker"
            label="证件类型"
            placeholder="点击选择证件类型"
            @click="showType = true"
          />
          <van-popup v-model:show="showType" position="bottom">
            <van-picker
              :columns="typeList"
              @confirm="onConfirmType"
              @cancel="showType = false"
            />
          </van-popup>
        </van-cell-group>
        <!-- 选择证件类型 -->
        <van-cell-group>
          <van-field
            v-model="tjrList.idNum"
            name="证件号码"
            label="证件号码"
            placeholder="请输入您的证件号码"
            :rules="[{ required: true, message: '请输入您的证件号码' }]"
          />
        </van-cell-group>
        <!-- 选择性别 -->
        <van-cell-group>
          <van-field name="radio" label="性别">
            <template #input>
              <van-radio-group v-model="tjrList.sex" direction="horizontal">
                <van-radio name="男">男</van-radio>
                <van-radio name="女">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </van-cell-group>
        <!-- 选择婚姻状态 -->
        <van-cell-group>
          <van-field name="radio" label="婚姻状态">
            <template #input>
              <van-radio-group
                v-model="tjrList.marriage"
                direction="horizontal"
              >
                <van-radio name="已婚">已婚</van-radio>
                <van-radio name="未婚">未婚</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </van-cell-group>
        <!-- 出生日期 -->
        <van-cell-group>
          <van-field
            v-model="tjrList.date"
            is-link
            readonly
            name="picker"
            label="出生日期"
            placeholder="点击选择出生日期"
            @click="showDate = true"
          />
          <van-popup v-model:show="showDate" position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="onConfirmDate"
              @cancel="showDate = false"
            />
          </van-popup>
        </van-cell-group>
        <!-- 输入手机号码 -->
        <van-cell-group>
          <van-field
            v-model="tjrList.mobile"
            name="手机号码"
            label="手机号码"
            placeholder="请输入您的手机号码"
            :rules="[
              { pattern: mobilePattern, message: '请输入正确的手机号码' },
              { required: true, message: '请输入您的手机号码' },
            ]"
          />
        </van-cell-group>
        <!-- TA是您 -->
        <van-cell-group>
          <van-field
            v-model="tjrList.relation"
            is-link
            readonly
            name="picker"
            label="TA是您"
            placeholder="请选择"
            @click="showRelation = true"
          />
          <van-popup v-model:show="showRelation" position="bottom">
            <van-picker
              :columns="relationList"
              @confirm="onConfirmRelation"
              @cancel="showRelation = false"
            />
          </van-popup>
        </van-cell-group>
      </van-form>
    </div>

    <!-- 添加按钮 -->
    <div class="save">
      <van-button type="primary" round block @click="saveTjr">
        保 存
      </van-button>
    </div>
  </div>
</template>

<script>
import "vant/lib/index.css";
import {
  NavBar,
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  RadioGroup,
  Radio,
  DatetimePicker,
  Button,
  Toast,
} from "vant";

export default {
  components: {
    vanNavBar: NavBar,
    vanForm: Form,
    vanField: Field,
    vanCellGroup: CellGroup,
    vanPopup: Popup,
    vanPicker: Picker,
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
    vanDatetimePicker: DatetimePicker,
    vanButton: Button,
  },
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  data() {
    return {
      tjrList: {
        name: "",
        idType: "",
        idNum: "",
        sex: "",
        marriage: "",
        date: "",
        mobile: "",
        relation: "",
      },
      typeList: ["身份证", "港澳通行证/护照"],
      relationList: ["本人", "父母", "子女", "配偶", "其他"],
      showType: false, //证件类型选择器
      showDate: false, //出生日期选择器
      showRelation: false, //TA是您 选择器
      minDate: new Date(1890, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      mobilePattern: /^1[3|4|5|7|8]\d{9}$/,
    };
  },
  methods: {
    // 选择证件类型的方法
    onConfirmType(value) {
      this.tjrList.idType = value;
      this.showType = false;
    },
    // 选择出生日期的方法
    onConfirmDate(time) {
      let year = time.getFullYear();
      let Mon = time.getMonth() + 1;
      let day = time.getDate();
      let date = `${year}-${Mon}-${day}`;
      this.tjrList.date = date;
      this.showDate = false;
    },
    // 选择 “TA是您” 的方法
    onConfirmRelation(value) {
      this.tjrList.relation = value;
      this.showRelation = false;
    },
    // 保存体检人信息
    saveTjr() {
      if (this.tjrList.name === "") {
        Toast("请输入您的姓名");
        return;
      } else if (this.tjrList.idType === "") {
        Toast("请选择您的证件类型");
        return;
      } else if (this.tjrList.idNum === "") {
        Toast("请选择您的证件号码");
        return;
      } else if (this.tjrList.sex === "") {
        Toast("请选择您的性别");
        return;
      } else if (this.tjrList.marriage === "") {
        Toast("请选择您的婚姻状态");
        return;
      } else if (this.tjrList.date === "") {
        Toast("请选择您的出生日期");
        return;
      } else if (this.tjrList.mobile === "") {
        Toast("请输入您的手机号码");
        return;
      } else if (this.tjrList.relation === "") {
        Toast("关系不能为空");
        return;
      }
      this.$refs.form
        .validate()
        .then(() => {
          this.$router.push({
            name: "TjrxxList",
          });
          Toast.success("添加成功");
        })
        .catch((err) => {
          Toast(err);
        });
    },
  },
  created() {
    if (this.$route.params) {
      this.tjrList = this.$route.params;
    }
  },
};
</script>

<style scoped>
.form {
  padding: 0.3rem;
  background: #fff;
}
.save {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  padding: 0.2rem;
}
</style>
