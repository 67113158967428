<template>
    <div id="app">
        <router-view />
    </div>
</template>

<style>
html body {
    min-height: 100vh;
}
#app {
    min-height: 100vh;
}
</style>
<script>
import { mapState } from "vuex";

export default {
    name: "App",
    computed: {
        ...mapState(["network"]),
    },
    created(){
        
    },
    methods: {
        // 通过跳转一个空页面再返回的方式来实现刷新当前页面数据的目的
        onRefresh() {
            this.$router.replace("/refresh");
        },
    }
};
</script>

