import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Home from '@/views/Home.vue'
import ProjectDetail from '@/views/Booking/ProjectDetail.vue'
import Order from '@/views/Booking/Order.vue'
import OrderList from '@/views/User/OrderList.vue'
import OrderDetail from '@/views/User/OrderDetail.vue'
import User from '@/views/User/User.vue'
import Goods from '@/views/User/Goods.vue'
import Examinator from '@/views/User/Examinator.vue'
import AddExaminator from '@/views/User/AddExaminator.vue'
import Login from '@/views/Common/Login.vue'
import Map from '@/views/Common/Map.vue'
const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Map',
    name: 'Map',
    component: Map
  },
  {
    path: '/ProjectDetail',
    name: 'ProjectDetail',
    component: ProjectDetail
  },
  {
    path: '/Examinator',
    name: 'Examinator',
    component: Examinator
  },
  {
    path: '/AddExaminator',
    name: 'AddExaminator',
    component: AddExaminator
  },
  {
    path: '/OrderDetail',
    name: 'OrderDetail',
    component: OrderDetail
  },
  {
    path: '/Order',
    name: 'Order',
    component: Order
  },
  {
    path: '/User',
    name: 'User',
    component: User
  },
  {
    path: '/Goods',
    name: 'Goods',
    component: Goods
  },
  {
    path: '/OrderList',
    name: 'OrderList',
    component: OrderList
  },
 
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {

  if (to.meta.title) {
    document.title = to.meta.title

  } else {
    document.title = "衡阳市中心医院健康管理（体检）中心"
  }
  next()
})
export default router
