<template>
    <van-nav-bar title="订单列表" left-text="返回" left-arrow @click-left="onClickLeft">
        <template #right>
            <router-link to="/User">
                <i class="van-badge__wrapper van-icon van-icon-friends-o" style="font-size:25px"></i>
            </router-link>
        </template>
    </van-nav-bar>
    <div class="container">
        <div class="tijian_order_wrap">
            <ul class="hd">
                <li v-for="(item,index) in tabList" :key="index" @click="tabSelect(index)">
                    <a :class="item.checked?'active':''">
                        <span>{{item.name}}</span>
                        <i v-if="item.num>0">{{item.num}}</i>
                    </a>
                </li>
            </ul>

            <ul class="bd">
                <li v-for="(item,index) in orderList" :key="index" @click="toPage('OrderDetail',item.id)">
                    <div class="top" >
                        <img :src="item.image">
                        <div class="center">
                            <h3>{{item.name}}</h3>
                            <p>下单时间：{{item.date}}</p>
                            <span class="price2" v-if="item.status!=0">
                                ￥
                                <b>{{item.price}}</b>
                            </span>
                        </div>
                        <span v-if="item.status==0" class="status_color_default">已取消</span>
                        <span v-if="item.status!=0" class="status_color_primary">{{item.statusText}}</span>
                    </div>
                    <div class="price">
                        需付：<span>￥<b>{{item.price}}</b></span>
                    </div>
                    <div class="bottom">
                        <a v-if="item.status==0" @click.stop="toPage('ProjectDetail',item.id)" class="btn_order btn_default">再次购买</a>
                        <label v-if="item.status==1" style="font-size:0.25rem;font-weight:800;display: inline-block;padding-right: 0.8rem;">倒计时：<span class="djs" style="color:#ff6544;">{{item.remainTime}}</span></label>
                        <a v-if="item.status!=0" @click.stop="cancelOrder(item.id)" class="btn_order btn_default order_delete">取消订单</a>
                        <a v-if="item.status==1" class="btn_order btn_warn">立即支付</a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import "vant/lib/index.css";
import { ref ,getCurrentInstance} from "vue";
import { Dialog, NavBar } from "vant";

export default {
    components: {
        vanNavBar: NavBar,
        vanDialog: Dialog,
    },
    setup() {
        const onClickLeft = () => history.back();
        const active = ref(0);

        return {
            onClickLeft,
            active,
        };
    },
    created() {
        console.log(new Date().getTime());
        this.orderList[1].time=new Date().getTime()- 1000 * 60 * 60;
        this.orderList[2].time=new Date().getTime();
        if (this.$route.query.type) this.type = this.$route.query.type;
        this.getOrderData(this.orderList);
    },
    methods: {
        getOrderData(value) {
            const {proxy} = getCurrentInstance();
            var statusText = ["已取消", "待支付", "待预约", "待体检", "退款"];
            var _this=this;
            value.forEach((item, index) => {
                if (item.status == 1) {
                    item.interval = "";
                    //设置订单失效时间
                    var time = Number(item.time) + 1000 * 60 * 60*2;
                    var currentTime = new Date().getTime();
                    var cm = time - currentTime;
                    if (cm <= 1000 * 60 * 60 * 2 && cm > 0)
                        item.remainTime = proxy.$common.toHHmmss(cm);
                    proxy.$common.setTimer(cm, item);
                }
                item.date = proxy.$common.format(Number(item.time));
                item.statusText = statusText[item.status];
            });
        },
        cancelOrder(id) {
            Dialog.confirm({
                title: "",
                message: "是否取消该订单",
            })
                .then(() => {
                    // on confirm
                    var i;
                    this.orderList.forEach((item, index) => {
                        if (item.id == id) {
                            i = index;
                        }
                        if (item.status == 1) clearInterval(item.interval);
                    });
                    this.orderList.splice(i, 1);
                    this.getOrderData(this.orderList)
                    console.log(this.orderList, i);
                })
                .catch(() => {
                    // on cancel
                });
        },
        tabSelect(i) {
            this.tabList.forEach((item, index) => {
                if (index == i) {
                    item.checked = true;
                } else {
                    item.checked = false;
                }
            });
        },
        toPage(page,id) {
            this.$router.push({
                name: page,
                query: { id: id },
            });
        },
    },
    data() {
        return {
            type: 0,
            intervalNum: 0,
            intervalList: [],
            tabList: [
                {
                    name: "全部",
                    checked: true,
                    num: 0,
                },
                {
                    name: "待付款",
                    checked: false,
                    num: 1,
                },
                {
                    name: "待预约",
                    checked: false,
                    num: 0,
                },
                {
                    name: "待体检",
                    checked: false,
                    num: 2,
                },
                {
                    name: "退款",
                    checked: false,
                    num: 1,
                },
            ],
            orderList: [
                {
                    image: "https://img.kktijian.com:8443/gr/img/OldImages/PC网站图/入职体检/01-7-入职-男女.png",
                    status: 0,
                    name: "入职体检套餐3",
                    time: "1645431766617",
                    price: "345.00",
                    id: 1,
                },
                {
                    image: "https://img.kktijian.com:8443/gr/img/OldImages/PC网站图/入职体检/01-7-入职-男女.png",
                    status: 1,
                    name: "基础套餐（男）",
                    time: "1645597921123",
                    price: "345.00",
                    id: 23,
                },
                {
                    image: "https://img.kktijian.com:8443/gr/img/OldImages/PC网站图/入职体检/01-7-入职-男女.png",
                    status: 1,
                    name: "入职体检套餐3",
                    time: "1645588680230",
                    price: "3452.00",
                    id: 24,
                },
            ],
        };
    },
};
</script>

<style scoped>
.container {
    padding-bottom: 50px;
}
</style>