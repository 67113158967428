<template>
    <div class="container">
        <div class="map_return">
            <a href="javascript:history.go(-1)">&nbsp;</a>
        </div>
        <div id="map" style="width: 100%;height: 90vh"></div>
        <div class="map_tjjg">
            <div class="tjjg">
                <b>衡阳市中心医院健康管理（体检）中心</b>
                <em></em>
                <span>公立三甲</span>
            </div>
            <div class="map_link">
                <a href="https://map.baidu.com/mobile/webapp/place/linesearch/foo=bar/end=word="></a>
            </div>
        </div>
    </div>

</template>

<script>
import "vant/lib/index.css";
import { ref } from "vue";

export default {
    components: {},
    setup() {
        const active = ref(1);
        return {
            active,
        };
    },
    mounted() {
        this.initMap();
    },
    data() {
        return {};
    },
    methods: {
        initMap() {
            // 百度地图API功能

            var lng = "112.5695747646";
            var lat = "26.9043737742";
            var map = new BMap.Map("map");
            map.centerAndZoom(new BMap.Point(lng, lat), 18); //创建地图给一个默认坐标
            map.enableScrollWheelZoom(true);
            var mk1 = new BMap.Marker(new BMap.Point(lng, lat), 18);
            map.addOverlay(mk1);
            map.centerAndZoom(new BMap.Point(lng, lat), 18);
        },
    },
};
</script>

<style scoped>
/*地图导航*/
.map_all {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    min-width: 320px;
    max-width: 640px;
}
.map_body {
    float: left;
    width: 100%;
    height: 100%;
}

.map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 75px;
    overflow: hidden;
    z-index: 1;
}
.map_tjjg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 75px;
    z-index: 2;
    background-color: #fff;
    border-top: #e8e8e8 1px solid;
}
.map_tjjg .tjjg {
    position: absolute;
    top: 5px;
    left: 10px;
    right: 75px;
    bottom: 0;
    line-height: 21px;
}
.map_tjjg .tjjg b {
    float: left;
    width: 100%;
    height: 21px;
    font-size: 14px;
    color: #4b4643;
    overflow: hidden;
}
.map_tjjg .tjjg em {
    float: left;
    width: 100%;
    height: 21px;
    font-size: 11px;
    color: #999999;
    font-style: normal;
    overflow: hidden;
}
.map_tjjg .tjjg span {
    float: left;
    width: auto;
    height: 21px;
    font-size: 12px;
    color: #4b4643;
    overflow: hidden;
    margin-right: 10px;
}
.map_return {
    position: absolute;
    top: 13px;
    left: 13px;
    width: 34px;
    height: 28px;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr=#4c000000, endColorstr=#4c000000);
}
.map_return a {
    float: left;
    width: 100%;
    height: 100%;
    background: url(../../assets/img/map_return.png) center no-repeat;
    background-size: 10px;
}
.map_tjjg .map_link {
    float: right;
    width: 70px;
    height: 75px;
    margin-right: 5px;
}
.map_tjjg .map_link a {
    float: left;
    width: 100%;
    height: 100%;
    background: url(../../assets/img/map_icon.png) center no-repeat;
    background-size: 50px;
}
</style>