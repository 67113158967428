<template>
    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onClickLeft">
        <template #right>
            <router-link to="/User">
                <i class="van-badge__wrapper van-icon van-icon-friends-o" style="font-size:25px"></i>
            </router-link>
        </template>
    </van-nav-bar>
    <div class="container">
        <ul class="collection_list">
            <li v-for="(item,index) in goodsList" :key="index">
                <a @click="toPage(item.id)">
                    <div class="img"><img :src="item.image"></div>
                    <div class="content">
                        <h3 class="one-txt-cut">{{item.name}}</h3>
                        <div>
                            <span class="price">￥<b>{{item.price}}</b></span>
                        </div>
                        <dl>
                            <dt class="one-txt-cut">{{item.address}}</dt>
                            <dd @click.stop="deleteGoods(item.id)"><span class="delet_collect_btn" ><img src="@/assets/img/icon/delet.png" alt=""></span></dd>
                        </dl>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import "vant/lib/index.css";
import { ref } from "vue";
import { Dialog, NavBar } from "vant";

export default {
    components: {
        vanNavBar: NavBar,
        vanDialog: Dialog,
    },
    setup() {
        const onClickLeft = () => history.back();
        const active = ref(0);

        return {
            onClickLeft,
            active,
        };
    },
    created() {
        if(this.$route.query.type) this.type=this.$route.query.type;
        if (this.type == 1) {
            this.title = "我的收藏";
        }else if (this.type == 2) {
            this.title = "浏览记录";
        }
        this.goodsList = [
            {
                image: "https://img.kktijian.com:8443/gr/img/OldImages/PC网站图/入职体检/01-7-入职-男女.png",
                name: "入职体检套餐3",
                price: "345.00",
                address:
                    "华新开发区光辉街31号（市委西头），衡阳市中心医院华新分院二楼体检中心",
                id: 1,
            },
            {
                image: "https://img.kktijian.com:8443/gr/img/OldImages/PC网站图/疾病筛查/09-5-疾病筛查-女.png",
                name: "关爱女性体检套餐",
                price: "1775.72",
                address:
                    "华新开发区光辉街31号（市委西头），衡阳市中心医院华新分院二楼体检中心",
                id: 2,
            },
        ];
    },
    methods: {
        deleteGoods(id) {
            console.log(id)
            if(this.type==1){
                var text="收藏";
            }else if(this.type==2){
                var text="记录";
            }
            Dialog.confirm({
                title: "",
                message:
                    "是否删除该"+text,
            })
                .then(() => {
                    // on confirm
                })
                .catch(() => {
                    // on cancel
                });
        },
        toPage(id) {
            this.$router.push({
                name: "ProjectDetail",
                query: { id: id },
            });
        },
    },
    data() {
        return {
            title: null,
            type:1,
            goodsList: null,
        };
    },
};
</script>

<style scoped>
.container {
    padding-bottom: 50px;
}
</style>