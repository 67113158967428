<template>
    <div class="banner_carousel">
        <van-swipe :autoplay="3000" lazy-render>
            <van-swipe-item v-for="image in images" :key="image">
                <img :src="image" class="w100" />
            </van-swipe-item>
        </van-swipe>
    </div>
    <div class="container">
        <div class="jg_info" style="border-radius: 0 0 0.16rem 0.16rem">
            <h2>衡阳市中心医院健康管理（体检）中心</h2>
            <div class="jg_two" v-if="distance">
                <span class="jg_mk"></span>
                <span class="jg_juli">距离您{{distance}}km</span>
            </div>
            <div class="jg_time">
                <img src="@/assets/img/icon/time.png">
                <div style="overflow:hidden;">
                    周一至周五： 8:00-11:30
                </div>
            </div>
            <div class="jg_dz">
                <img src="@/assets/img/icon/dz.png">
                <span>华新开发区光辉街31号（市委西头），衡阳市中心医院华新分院二楼体检中心</span>
                <router-link to="/Map">地图<img src="@/assets/img/icon/arrow-right.png"></router-link>
            </div>
        </div>
        <div id="bz05" @click="show=true"><img src="@/assets/img/icon/bz09.png"></div>
        <van-popup v-model:show="show" position="bottom" round :style="{ height: '5.55rem' }">
            <div class="common_model_fwbz">
                <div class="hd">服务保障<span class="close" @click="show=false;"><img src="@/assets/img/icon/clear.png" alt="" data-isloaded="1"></span></div>
                <div class="con">
                    <ul>
                        <li>
                            <div class="t1"><img src="@/assets/img/icon/bz_4.png" alt="" data-isloaded="1"></div>
                            <div class="t2">
                                <div class="t2_1">官方渠道</div>
                                <div class="t2_2">医院自有平台，官方线上渠道，可享受极速预约通道</div>
                            </div>
                        </li>
                        <li>
                            <div class="t1"><img src="@/assets/img/icon/bz_2.png" alt="" data-isloaded="1"></div>
                            <div class="t2">
                                <div class="t2_1">免费改期</div>
                                <div class="t2_2">
                                    每位客户拥有2次通过网站直接改期的机会。如因特殊情况需再次改期，只需联系康康体检网客服审核，确认原因后即可改期。改期不收任何服务费。
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="t1"><img src="@/assets/img/icon/bz_3.png" alt="" data-isloaded="1"></div>
                            <div class="t2">
                                <div class="t2_1">未检可退</div>
                                <div class="t2_2">
                                    用户支付成功但未预约体检时间，可以全额退款。
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </van-popup>
        <div class="main_tab_wrap">
            <van-tabs v-model:active="active">
                <van-tab title="体检套餐">
                    <div class="bdLine"></div>
                    <div class="multi_span" style="overflow-x: scroll;">
                        <span v-for="(item,index) in typeList" :key="index" :class="item.checked?'active':''" @click="typeSelect(item.id)">{{item.name}}</span>
                    </div>
                    <div class="bdLine"></div>
                    <div class="list_wrap01">
                        <div v-for="(item,index) in packageList" :key="index" class="list01_item01" @click="toPage('ProjectDetail',item)" style="display: block;">
                            <div class="list01_top01">
                                <div class="img">
                                    <img :src="item.image" :alt="item.title">
                                </div>
                                <div class="con">
                                    <div class="t1"><span v-if="item.label">{{item.label}}</span>{{item.title}}</div>
                                    <div class="t2">
                                        <div class="t2_1">{{item.desc}}</div>
                                    </div>
                                    <div class="t3">
                                        <div class="t3_1">
                                            <div class="t3_1_1">￥<span>{{item.price}}</span></div>

                                        </div>
                                        <div class="t3_2">已约{{item.num}}</div>
                                    </div>
                                    <div class="t4" v-if="item.hot">
                                        <div>
                                            <div class="t4_1">本周热销</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="机构介绍">
                    <div class="jgjs_box">
                        <div class="item">
                            <div class="title"><img src="@/assets/img/icon/circle.png">机构介绍</div>
                            <div class="jgjs_content content">
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont, text-align:justify;font-size:16px;"> <span style="color:#3F3F3F;font-size:15px;">衡阳市中心医院体检中心创建于2002年9月，设有专门的体检场地，拥有一支高素质的医疗队伍和一批先进的诊断设备，融健康体检、健康咨询、健康管理为一体，设施完善、项目齐全、服务规范、收费合理。</span><span style="color:#3F3F3F;font-size:15px;">体检中心连续多年承担全市老干部、高（中）考招生、公务员录用、招工、招干、征兵体检、居民健康体检等体检任务，深受市民好评。</span><span style="font-size:15px;"></span>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont, text-align:justify;font-size:16px"><img src="http://img.kktijian.com/gr/img/upload/image/20210128/20210128145344_1425.jpg" alt="">
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont, text-align:justify;font-size:16px"><br>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont, font-size:16px;text-align:center"> <span style="color:#3F3F3F;"><span style="font-size:14px;">衡阳市中心医院院本部</span></span>
                                </p>
                                <div>
                                    <br>
                                </div>
                                <img src="http://img.kktijian.com/gr/img/upload/image/20210128/20210128145412_5011.jpg" alt="">
                                <p>
                                    <br>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "><span style="font-family:-apple-system, BlinkMacSystemFont ， color:#3f3f3f;font-size:14px; ">衡阳市</span><span style="font-family:-apple-system, BlinkMacSystemFont ， color:#3f3f3f;font-size:14px; ">中心医院华新分院</span>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "><br>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "> <span style="color:#3F3F3F;font-size:15px;"></span>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "> <span style="color:#3F3F3F;font-size:15px;">一、已具备下列开展健康体检条件：</span>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "> <span style="color:#3F3F3F;font-size:15px;">1.具有相对独立的健康体检场所及候检场所，建筑面积约3400平方米，单个独立的检查室使用面积不少于10平方米；</span>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "> <span style="color:#3F3F3F;font-size:15px;">2.具有副高以上专业技术职位任职资格的执业医师3人，中级以上专业技术职位任职资格的执业医师8人；</span>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "> <span style="color:#3F3F3F;font-size:15px;">3.具有15名注册护士；</span>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "> <span style="color:#3F3F3F;font-size:15px;">4.登记的诊疗科目包括内科、外科、妇产科、眼科、耳鼻喉科、口腔科、医学影像科和医学检验科；</span>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "> <span style="color:#3F3F3F;font-size:15px;">5.具有满足健康体检需要的其他卫生技术人员;如心电图、B超、放射科等。</span>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "> <span style="color:#3F3F3F;font-size:15px;">6.具有符合开展健康体检要求的仪器设备。</span>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "><img src="http://img.kktijian.com/gr/img/upload/image/20210128/20210128145946_9926.jpg" alt="">
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "> <span style="color:#3F3F3F;font-size:15px;"></span>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "> <span style="color:#3F3F3F;font-size:15px;">二、严格按照《健康体检项目目录》制定坚持了以下原则；</span>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "> <span style="color:#3F3F3F;font-size:15px;">1．达到健康体检的目的，即了解受检者的健康状况，并早期发现疾病隐患，如肿瘤和常见慢性疾病等；</span>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "> <span style="color:#3F3F3F;font-size:15px;">2．区分健康体检项目和疾病诊断项目。主要用于疾病诊断用途的技术和方法不列入健康体检项目；</span>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "> <span style="color:#3F3F3F;font-size:15px;">3．保证健康体检的质量和安全，采用临床证明已经成熟、准确、敏感的诊断技术和方法。侵入性和存在较大风险的项目不列入健康体检项目；</span>
                                </p>
                                <p style="color:#333333;font-family:-apple-system, BlinkMacSystemFont ， text-align:justify;font-size:16px; "> <span style="color:#3F3F3F;font-size:15px;">4．有利于体现健康体检成本效益最优原则，引导健康体检合理进行，避免浪费。</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="预定须知">
                    <div class="jgjs_box">
                        <div class="item">
                            <div class="title"><img src="@/assets/img/icon/circle.png">体检流程</div>
                            <div class="content">
                                <img src="@/assets/img/lct.png" style="margin-bottom: 0.4rem;">
                                <ol class="num_ol">
                                    <li>选择需要的体检套餐，完成下单</li>
                                    <li>可在付款完成后，“立即预约”或到“我的订单”中预约体检。</li>
                                    <li>
                                        体检当日现场出示身份证即可体检， 注 : 到医院无需另行支付其他费用
                                        <!-- 大连大学新华医院 -->
                                        <span>(现场加项、申请快递报告除外)</span>
                                        。
                                    </li>
                                    <li>
                                        体检报告一般在3-15个工作日出结果，
                                        <span>部分医院支持在线查询报告、报告邮寄，各医院领取时间、方式不同，</span>
                                        具体的报告领取方式和时间可现场咨询医院工作人员。
                                    </li>
                                </ol>
                            </div>
                        </div>

                        <div class="bdLine"></div>
                        <div class="item">
                            <div class="title"><img src="@/assets/img/icon/circle.png">体检须知</div>
                            <div class="content">
                                <ul class="tijian_step">
                                    <li>
                                        <div class="step_title"><span>01</span>体检前</div>
                                        <div class="hs_left_border">
                                            <ol class="num_ol">
                                                <li>体检前一天请您清淡饮食,勿饮酒、勿劳累。体检当天请空腹禁食。</li>
                                                <li>体检前一天要注意休息，晚上8点后不再进食。避免剧烈运动和情绪激动，保证充足睡眠，以免影响体检结果。</li>
                                                <li>例假期间不宜做妇科、尿液检查。</li>
                                            </ol>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="step_title"><span>02</span>体检中</div>
                                        <div class="hs_left_border">
                                            <ol class="num_ol">
                                                <li>需空腹检查的项目为抽血、腹部B超、数字胃肠，胃镜及其它标注的体检项目。</li>
                                                <li>做膀胱、子宫、附件B超时请勿排尿，如无尿需饮水至膀胱充盈。做妇科检查前应排空尿。</li>
                                                <li>未婚女性不做妇科检查；怀孕的女性请预先告知医护人员,不安排做放射及其他有影响的检查。</li>
                                                <li>做放射线检查前,请您除去身上佩戴的金银、玉器等饰物。</li>
                                                <li>核磁共振检查，应禁止佩带首饰、手表、传呼、手机等金属物品，磁卡也不应带入检查室，以防消磁。</li>
                                            </ol>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="step_title"><span>03</span>体检后</div>
                                        <div class="hs_left_border">
                                            <ol class="num_ol">
                                                <li>全部项目完毕后请您务必将体检单交到前台。</li>
                                                <li>请您认真听取医生的建议,及时复查、随诊或进一步检查治疗。</li>
                                                <li>请您保存好体检结果，以便和下次体检结果作对照也可作为您就医时的资料。</li>
                                            </ol>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
        </div>

    </div>
    <van-tabbar v-model="active">
        <van-tabbar-item icon="home-o" to="/">主页</van-tabbar-item>
        <van-tabbar-item icon="friends-o" to="/User">我的</van-tabbar-item>
    </van-tabbar>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { Tabbar, TabbarItem, Swipe, SwipeItem, Popup, Tab, Tabs } from "vant";

export default {
    components: {
        vanTabbar: Tabbar,
        vanTabbarItem: TabbarItem,
        vanSwipe: Swipe,
        vanSwipeItem: SwipeItem,
        vanPopup: Popup,
        vanTab: Tab,
        vanTabs: Tabs,
    },
    setup() {
        const active = ref(0);
        return { active };
    },
    created() {
        // axios.get("/w/home/queryPosition?cityId=985&positionId=7&springsign=CE8AC61F08414CE0EA821961C7A7463E369A1990").then((res) => {
        //     console.log( res.data.data);
        // });
        var geolocation = new BMap.Geolocation();
         var _this = this;
        geolocation.getCurrentPosition(function (r) {
            console.log(r);
            if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                var mk = new BMap.Marker(r.point);
                console.log(mk);
                _this.getAddress(r.point);
            } else {
                console.log("failed" + this.getStatus());
            }
        });
        this.typeList.forEach((item, index) => {
            item.checked = false;
        });
        this.typeList.unshift({
            name: "全部",
            id: 0,
            checked: true,
        });
    },
    methods: {
        //获取地址信息
        getAddress(point) {
            var gc = new BMap.Geocoder();
            var map = new BMap.Map("allmap");
            var _this = this;
            // 反解析
            gc.getLocation(point, function (rs) {
                var pointA = new BMap.Point(rs.point.lng, rs.point.lat); // 创建点坐标A
                var pointB = new BMap.Point(112.5695747646, 26.9043737742); // 创建点坐标B
                map.getDistance(pointA, pointB); //两点间的距离
                console.log(map.getDistance(pointA, pointB));
                _this.distance = Number(
                    map.getDistance(pointA, pointB) / 1000
                ).toFixed(2);
            });
        },
        toPage(page, val) {
            console.log(val);
            this.$router.push({
                name: page,
                query: {
                    id: val.id,
                },
            });
        },
        typeSelect(id) {
            this.typeList.forEach((item, index) => {
                if (item.id == id) {
                    item.checked = true;
                } else {
                    item.checked = false;
                }
            });
        },
    },
    data() {
        return {
            show: false,
            distance: null,
            images: [
                "https://img.kktijian.com/gr/img/AdImgs/2020-06-10/cc99add33fd44edf8d571b871f5fb4aa.JPG",
                "http://www.cqkuanren.com/d/file/mobilebanner1/2021-02-20/a3cb2d29134af3625d55e441079679d4.jpg",
            ],
            typeList: [
                {
                    name: "入职体检",
                    id: 1,
                },
                {
                    name: "全身体检",
                    id: 2,
                },
                {
                    name: "青年体检",
                    id: 3,
                },
                {
                    name: "中年体检",
                    id: 4,
                },
                {
                    name: "老年体检",
                    id: 5,
                },
            ],
            packageList: [
                {
                    image: "https://img.kktijian.com:8443/gr/img/OldImages/PC网站图/入职体检/01-7-入职-男女.png",
                    title: " 入职体检套餐3 ",
                    desc: " 适用于求职人员 ",
                    price: "345",
                    num: "2302",
                    hot: true,
                    label: "",
                    id: 1,
                },
                {
                    image: "https://img.kktijian.com:8443/gr/img/OldImages/PC网站图/全身体检/02-13-全身体检-男女.png",
                    title: " 体检方案G ",
                    desc: " 肿瘤筛查丨甲状腺彩超丨双能量X线骨密度仪丨肺功能检测丨颈动脉彩超 ",
                    price: "5517",
                    num: "1265",
                    hot: false,
                    label: "全面",
                    id: 2,
                },
                {
                    image: "https://img.kktijian.com:8443/gr/img/OldImages/PC网站图/青年体检/03-1-青年-男.png",
                    title: " 基础套餐（男） ",
                    desc: " 适用于求职人员 ",
                    price: "818",
                    num: "3425",
                    hot: false,
                    label: "基础",
                    id: 3,
                },
            ],
        };
    },
};
</script>

<style scoped>
.main_tab_wrap .tab_content {
    padding-top: 0;
}

.list_wrap01 {
    padding: 0 0.3rem 0.3rem;
    background: #f5f5f5;
}

.list01_item {
    background: #ffffff;
    border-radius: 0.1rem;
    color: #999999;
    font-size: 0.24rem;
    padding: 0.3rem 0.3rem 0.26rem;
    margin-bottom: 0.2rem;
    display: block;
    box-shadow: 0rem 0rem 0rem 0rem rgba(233, 233, 233, 0.59);
}

.list01_item:last-child {
    margin-bottom: 0;
}

.list01_top {
    display: flex;
    justify-content: space-between;
}

.list01_top .img {
    position: relative;
    display: flex;
    justify-content: center;
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 0.2rem;
    overflow: hidden;
}

.list01_top .img img {
    height: 100%;
    width: auto;
}

.list01_top .mImg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
}

.list01_item .con {
    flex: 1;
    width: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.list01_t1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list01_t1 .t1 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
    width: 0;
    font-size: 0.3rem;
    line-height: 0.32rem;
    color: #333333;
}

.list01_t1 .t2 {
    display: flex;
    align-items: center;
    font-size: 0.24rem;
    color: #ec3d30;
    margin-left: 0.2rem;
}

.list01_t1 .t2 img {
    width: 0.24rem;
    margin-right: 0.1rem;
}

.list01_t1 .t2 span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.35rem;
    height: 0.36rem;
    background: #ec3d30;
    border-radius: 0.06rem;
    color: #ffffff;
    font-size: 0.24rem;
    margin: 0 0.06rem;
}

.list01_t2 {
    overflow-x: auto;
    white-space: nowrap;
}

.list01_t2 span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.2rem;
    line-height: 0.32rem;
    padding: 0 0.09rem;
    margin-right: 0.12rem;
    border: 1px solid #e6e6e6;
    color: #989898;
    border-radius: 0.16rem;
}

.list01_t2 .on {
    border: 1px solid #ffc9c8;
    color: #ff4341;
}

.list01_t3 {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.list01_t3 .t1 {
    font-size: 0.2rem;
    color: #ff4341;
}

.list01_t3 .t1 span {
    font-weight: bold;
    font-size: 0.36rem;
    line-height: 0.38rem;
}

.list01_bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 0.2rem;
    border-top: 1px solid #f2f2f2;
    margin-top: 0.3rem;
}

.list01_bottom img {
    width: 0.26rem;
    margin-top: 0.06rem;
    margin-right: 0.1rem;
}

.list01_bottom .t2 {
    flex: 1;
    width: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 0.4rem;
}

.list01_item01 {
    padding: 0.3rem;
    background: #ffffff;
    border-radius: 0.18rem;
    margin-bottom: 0.2rem;
    font-size: 0.25rem;
    color: #666666;
    display: block;
}

.list01_top01 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.list01_top01 .img {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    border-radius: 0.1rem;
    overflow: hidden;
    margin-right: 0.18rem;
    width: 1.56rem;
    height: 1.56rem;
    background: #f5f5f5;
    position: relative;
}

.list01_top01 .img img {
    height: 100%;
    width: auto;
}

.list01_top01 .con {
    flex: 1;
    width: 0;
}

.list01_top01 .t1 {
    font-size: 0.3rem;
    line-height: 0.32rem;
    font-weight: bold;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.list01_top01 .t2 {
    line-height: 0.32rem;
    margin: 0.1rem 0 0.14rem;
    height: 0.66rem;
    color: #666666;
}

.list01_top01 .t2_1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.list01_top01 .t2 span {
    display: inline-block;
}

.list01_top01 .t1 span {
    display: inline-block;
    font-size: 0.24rem;
    color: #3c9dff;
    background: #e7f4ff;
    padding: 0 0.07rem;
    vertical-align: 1px;
    margin-right: 0.1rem;
}

.list01_top01 .t3 {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 0.24rem;
    color: #999999;
}

.list01_top01 .t3_1 {
    display: flex;
    align-items: flex-end;
}

.list01_top01 .t3_2 {
    position: relative;
}

.list01_top01 .t3_1_1 {
    color: #ff4341;
    font-size: 0.24rem;
    line-height: 0.26rem;
}

.list01_top01 .t3_1_1 span {
    font-weight: bold;
    font-size: 0.36rem;
}

.list01_top01 .t3_1_2 {
    color: #b3b3b3;
    font-size: 0.24rem;
    line-height: 0.26rem;
    margin-left: 0.14rem;
}

.list01_top01 .t3_1_2 span {
    font-size: 0.28rem;
}

.list01_top01 .t4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.1rem;
    margin-bottom: -0.1rem;
}

.list01_top01 .t4_1 {
    line-height: 0.3rem;
    background: #ffecec;
    border-radius: 0.15rem;
    font-size: 0.22rem;
    display: inline-block;
    padding: 0 0.12rem;
    color: #fe1605;
    margin-top: 0.1rem;
}

.list01_top01 .t4_2 {
    display: flex;
    align-items: center;
    font-size: 0.22rem;
    color: #666666;
}

.list01_top01 .t4_2_1 {
    display: flex;
    align-items: center;
    font-size: 0.23rem;
    color: #ec3d30;
    margin-left: 0.04rem;
}

.list01_top01 .t4_2_1 span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 0.33rem;
    height: 0.32rem;
    background: #ec3d30;
    border-radius: 0.06rem;
    font-size: 0.23rem;
    color: #ffffff;
    margin: 0 0.06rem;
}

.list01_bottom01 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 0.3rem;
    border-top: 1px solid #f5f5f5;
    margin-top: 0.28rem;
    position: relative;
}

.list01_bottom01 .t1 {
    display: flex;
    align-items: center;
    font-size: 0.26rem;
    line-height: 0.28rem;
    color: #666666;
    margin-top: 0.04rem;
}

.list01_bottom01 .t1 img {
    width: 0.22rem;
    margin-right: 0.12rem;
}

.list01_bottom01 .t2 {
    flex: 1;
    width: 0;
    position: relative;
    font-size: 0;
    height: 0.42rem;
    overflow: hidden;
    padding-right: 0.2rem;
}

.list01_bottom01.open .t2 {
    height: auto;
}

.main_tab_wrap .tab_content {
    background: #f5f5f5;
}

.list01_bottom01 .t2_toggle {
    position: absolute;
    right: -0.1rem;
    top: 0.3rem;
    z-index: 2;
    font-size: 0;
}

.list01_bottom01 .t2_toggle img {
    width: 0.19rem;
    height: auto;
}

.list01_bottom01.open .t2_toggle img {
    transform: rotate(180deg);
}

.list01_bottom01 .t2_toggle_01 {
    padding: 0.16rem 0rem 0.14rem 0;
    position: relative;
    background: #fff;
    cursor: pointer;
    width: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*.list01_bottom01 .t2_toggle_01::after {
                                        content: '';
                                        position: absolute;
                                        width: 0.6rem;
                                        height: 100%;
                                        left: -0.4rem;
                                        top: 0;
                                        background: linear-gradient(to left,#fff,transparent);
                                    }*/

.list01_bottom01 .t2 span {
    display: inline-block;
    font-size: 0.25rem;
    color: #666666;
    border: 1px solid #e6e6e6;
    border-radius: 0.04rem;
    margin: 0 0.12rem 0.12rem 0;
    line-height: 0.36rem;
    padding: 0 0.08rem;
}
</style>