<template>
  <div class="tjrxxList">
    <!-- 头部 -->
    <van-nav-bar
      title="体检人信息"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    >
      <template #right>
        <i
          class="van-badge__wrapper van-icon van-icon-friends-o"
          style="font-size: 25px"
        ></i>
      </template>
    </van-nav-bar>

    <!-- 体检人信息卡片 -->
    <div class="main">
      <ul>
        <li class="card" v-for="item in tjrList" :key="item.id">
          <van-swipe-cell>
            <!-- 卡片 -->
            <div class="card-main">
              <div class="card-top">
                <div class="card-title">
                  <img src="@/assets/img/img/man1.png" alt="" />
                  <h3>{{ item.name }}</h3>
                  <span>{{ item.relation }}</span>
                </div>
                <div class="card-compile" @click="editTjr(item)">
                  <img src="@/assets/img/img/xingzhuang.png" /> 编辑
                </div>
              </div>
              <div class="card-num">
                电话号码：<span>{{ item.mobile }}</span>
              </div>
              <div class="card-num">
                证件号码：<span>{{ item.idNum }}</span>
              </div>
            </div>
            <!-- 滑动删除 -->
            <template #right>
              <van-button
                square
                text="删除"
                type="danger"
                class="delete-button"
                @click="delTjr"
              />
            </template>
          </van-swipe-cell>
        </li>
      </ul>
    </div>

    <!-- 添加体检人 -->
    <div class="add">
      <van-button type="primary" round block @click="goAddTjr">
        添加体检人
      </van-button>
    </div>
  </div>
</template>

<script>
import "vant/lib/index.css";
import { NavBar, SwipeCell, Button, Toast } from "vant";

export default {
  components: {
    vanNavBar: NavBar,
    vanSwipeCell: SwipeCell,
    vanButton: Button,
  },
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  data() {
    return {
      tjrList: [
        {
          id: 1,
          name: "张三",
          idType: "身份证",
          idNum: "440204199101057894",
          sex: "男",
          marriage: "未婚",
          date: "1991-1-5",
          mobile: "13640111111",
          relation: "本人",
        },
        {
          id: 2,
          name: "王五",
          idType: "身份证",
          idNum: "440204199610057894",
          sex: "男",
          marriage: "未婚",
          date: "1991-10-5",
          mobile: "18711111111",
          relation: "本人",
        },
        {
          id: 3,
          name: "赵六",
          idType: "身份证",
          idNum: "440204199904207894",
          sex: "女",
          marriage: "未婚",
          date: "1991-4-20",
          mobile: "13666222222",
          relation: "本人",
        },
      ],
    };
  },
  methods: {
    goAddTjr() {
      this.$router.push({
        name: "AddExaminator",
      });
    },
    // 编辑体检人
    editTjr(data) {
      this.$router.push({
        name: "AddExaminator",
        params: data,
      });
    },
    // 删除体检人信息
    delTjr() {
      Toast.success("点击了删除");
    },
  },
};
</script>

<style scoped>
.tjrxxList {
  background: #f0f0f0;
  height: 100vh;
}
.main {
  width: 100%;
  padding: 0 0.2rem;
  margin-top: 0.2rem;
}
.card {
  margin-bottom: 0.3rem;
}
.card-main {
  padding: 0.2rem;
  background: #fff;
}
.card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-title {
  display: flex;
  align-items: center;
}
.card-title h3 {
  margin-left: 0.4rem;
  font-size: 0.3rem;
  color: #555555;
}
.card-title span {
  margin-left: 0.2rem;
  padding: 0.02rem 0.1rem;
  color: #fff;
  background: #1890ff;
}
.card-top img {
  width: 0.8rem;
  height: 0.8rem;
}
.card-compile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.02rem 0.1rem;
  border-radius: 0.44rem;
  border: 1px solid #f0f0f0;
}
.card-compile img {
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.1rem;
}
.card-num {
  font-size: 0.3rem;
  padding: 0.2rem 0 0 1.2rem;
}
.card-num span {
  font-size: 0.28rem;
}
.card-num:last-child {
  margin-bottom: 0.2rem;
}
.add {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 1.2rem;
  padding: 0.2rem;
  background: #fff;
}
</style>

<style>
.goods-card {
  margin: 0;
}
.delete-button {
  height: 100%;
}
</style>
