import { createStore } from 'vuex'

export default createStore({
  state: {
    isLogin : true
  },
  mutations: {
    setPrint(state, all) {
      state.isLogin = !state.isLogin;
    }
  },
  actions: {
  },
  modules: {
  }
})
