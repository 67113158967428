<template>
    <div class="container">

        <form id="form1" style="height:100%">
            <div id="login_page" style="height:100%">
                <img src="@/assets/img/logotop.png">
                <div class="login_top">
                    <div class="logo">
                        <router-link to="/">
                            <img src="https://img.kktijian.com:8443/gr/img/OldImages/JiGou/20210301/YY201509141935330001/bab7b698553f49fc9baf4e8cf2b8982a.jpg">
                        </router-link>    
                    </div>
                </div>
                <div class="login_form">
                    <div class="item">
                        <div class="left">
                            <img src="@/assets/img/icon/phone.png">
                            <input type="text" id="phone" name="phone" placeholder="请输入手机号码">
                        </div>
                    </div>
                    <div class="item">
                        <div class="left">
                            <img src="@/assets/img/icon/yzm.png">
                            <input type="text" id="validCode" name="validCode" placeholder="请输入图形验证码">
                        </div>
                        <div class="right">
                            <div class="yzm_box"><img src="/yygzh/ValidCode/index?v=0.048938323248536975"></div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="left">
                            <img src="@/assets/img/icon/psd.png">
                            <input type="text" id="code" name="code" placeholder="请输入验证码">
                        </div>
                        <div class="right">
                            <div class="get_yzm_box"><span class="can_click">获取验证码</span></div>
                        </div>
                    </div>
                    <div class="login_sub_box">
                        <a href="javascript:;"><img src="@/assets/img/loginbtn.png"><span>登 录</span></a>
                    </div>
                </div>
            </div>
        </form>
    </div>

</template>

<script>
import "vant/lib/index.css";
import { ref } from "vue";
import { Tabbar, TabbarItem, Popup } from "vant";

export default {
    components: {
        vanPopup: Popup,
        vanTabbar: Tabbar,
        vanTabbarItem: TabbarItem,
    },
    setup() {
        const active = ref(1);
        return {
            active,
        };
    },
    created() {},
    methods: {
        toPage(page, type) {
            this.$router.push({
                name: page,
                query: { type: type },
            });
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
.container {
    min-height: 100vh;
    background-color: white;
}
</style>